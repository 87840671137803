import { translate } from '@/domains/core/localization/translate';
export const {
  individualLoginText,
  individualLoginDescription,
  proLoginText,
  proLoginDescription,
  proPreRegisterDescription,
  proPreRegisterUrl
} = translate({
  "individualLoginText": "Soy un particular",
  "individualLoginDescription": "Acceder a mi cuenta",
  "proLoginText": "Soy un Profesional",
  "proLoginDescription": "Acceder a los precios Pro",
  "proPreRegisterDescription": "Pre-register",
  "proPreRegisterUrl": "http://pro.manomano.com/de/"
});