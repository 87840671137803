import { translate, translateFormatter, type RichTextFormatter } from '@/core/localization/translate';
export const {
  leonidasHelloText,
  leonidasLoginText
} = translate({
  "leonidasHelloText": "Hola",
  "leonidasLoginText": "Inicia sesi\xF3n"
});
export const leonidasMobileLoginText = translateFormatter<{
  br: RichTextFormatter;
}>("Inicia <br></br>sesi\xF3n", undefined, undefined, "es");